@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap');

* {
  font-family: 'Inter';
  color: #fff;
}

html, body {
  background-color: #04151f;
}

h1 {
  font-family: 'Inter';
  font-weight: bold;
  color: #efd6ac;
}

.container {
  display: block;
  max-width: 1300px;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.sidebar {
  float: left;
  height: 100%;
  background-color: #432534;
  padding: 10px 25px;
  width: 20%;
  max-width: 250px;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
}

.sidebar ul li {
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  transition: all 0.25s;
  cursor: pointer;
}

.sidebar ul li:not(:last-of-type) {
  margin-bottom: 10px;
}

.sidebar ul li.active {
  background-color: #fff;
  box-shadow: 2px 2px 12px black;
  color: #04151F;
}

.sidebar ul li:hover {
  background-color: #fff;
  box-shadow: 2px 2px 12px black;
  color: #04151F;
}

.App-header .container {
  display: flex;
  flex-direction: row;
}

input {
  color: #04151f;
}

.App-header .container input[type='text'],
.App-header .container input[type='password'] {
  height: 54px;
  font-size: 32px;
  margin-right: 10px;
  border: 0;
  padding-left: 10px;
}

div.center {
  justify-content: center;
}

div.form-lines {
  flex-direction: column !important;
}

div.form-lines input {
  margin-bottom: 20px;
  margin-right: 0 !important;
}

.App-header .container button {
  height: 54px;
  background-color: #f45866;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  padding: 0 15px;
  font-size: 16px;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
